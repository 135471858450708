import { Offer } from '@modules/catalog/catalog.interface';
import { FlatOffer } from '@modules/flat-offers/flat-offers.interface';
import { Notifications } from '@modules/login/login.interface';
import { Training } from '@modules/training/store/training.state';
import { createAction, props } from '@ngrx/store';
import { Agency } from '@shared/agency-pick/agency-pick/agency-pick.component';
import { IStoreState } from './store.state';

export const GetAllAgencyAction = createAction('[User] Get Agency List');
export const SetAllAgencyAction = createAction('[User] Set Agency List', props<{ agencyList: Agency[] }>());
export const NotificationsGetDailyTaskListAction = createAction('[Notifications] Get List DailyTask', props<{ dailyTask: Partial<Notifications>[] }>());
export const NotificationsGetListAction = createAction('[Notifications] Get List', props<{ notifications: Partial<Notifications>[] }>());
export const NotificationsUpdateVisualizedAction = createAction('[Notification] Update Visualized Notification', props<{ notification: Partial<Notifications> }>());
export const NotificationsUpdatedVisualizedAction = createAction('[Notification] Updated Visualized Notification', props<{ notification: Partial<Notifications>; dispatched?: boolean }>());
export const SetHomePageCommodityItemAction = createAction('[Catalog] Set HomePage Commodity Item', props<{ catalogCommodityHomeItem: Partial<Offer>[] }>());
export const SetHomePageBundleItemAction = createAction('[Catalog] Set HomePage Bundle Item', props<{ catalogBundleHomeItem: Partial<Offer>[] }>());
export const SetHomePageVasItemAction = createAction('[Catalog] Set HomePage Vas Item', props<{ catalogVasHomeItem: Partial<Offer>[] }>());
export const SetMandatoryTrainingFlagAction = createAction('[Training] Set Mandatory Training Flag ', props<{ hasMandatoryTraining: boolean }>());
export const TrainingHomepageLoadedListAction = createAction('[Training] Add Homepage Training', props<{ trainingListHomepage: Partial<Training>[]; totalCount: number }>());
export const FireBaseRemoteConfigAction = createAction('[Training] Add FirebaseConf', props<{ [key: string]: any }>());
export const SetFlatOfferAction = createAction('[FlatOffer] Set FlatOffer Item', props<{ flatOffer: Partial<FlatOffer> }>());
export const DeleteFlatOfferAction = createAction('[FlatOffer] Delete FlatOffer Item', props<{ flatOffer: Partial<FlatOffer> }>());
export const ResetPaStoreKey = createAction('[Store] Reset paStoreKey Key', props<{ emptyStoreState: Partial<IStoreState> }>());
