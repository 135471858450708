import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GuardSelector } from './../store/user/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(GuardSelector).pipe(
      mergeMap(el => {
        if (el) {
          return of(true);
        } else {
          this.router.navigate(['login']);
          return of(false);
        }
      })
    );
  }
}
