import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { CheckIndirizzoInstallazioneConnettivitaAction } from '@modules/wizard-sales/store/sales.actions';
import { SalesVasInBundleIsLuminea } from '@modules/wizard-sales/store/sales.selector';
import { Store } from '@ngrx/store';
import { response } from 'express';
import { take } from 'rxjs';

@Component({
  selector: 'iren-exit-funnel',
  templateUrl: './exit-funnel.component.html',
  styleUrls: ['./exit-funnel.component.scss']
})
export class ExitFunnelComponent implements OnInit {
  public url: string = '';
  public copy: string = 'Sei sicuro di voler uscire?';
  public isLuminea: boolean = false;
  public userLuminea: boolean;
  public checkCfAndFornitureAddress = 'Attenzione, il cliente risulta già inserito. Per proseguire l’inserimento, si prega di verificare i dati o annullare la vendita già esistente.';
  constructor(private dialogRef: MatDialogRef<any>, private router: Router, @Inject(MAT_DIALOG_DATA) public data: { logout: boolean; copy?: string }, private store: Store, private userService: UserService) {
    this.url = this.router.url;
    this.store
      .select(SalesVasInBundleIsLuminea)
      .pipe(take(1))
      .subscribe({
        next: res => {
          this.isLuminea = res;
          if (this.userService.getUserProfile().permissions?.length) {
            this.userLuminea = !!this.userService.getUserProfile().permissions?.find(el => el.id === 67);
          } else {
            this.userLuminea = !!this.userService
              .getUserProfile()
              .profile?.permissions.map(perm => perm.permission)
              .find(el => el.id === 67);
          }
        },
        error: error => {
          console.error(error);
        }
      });
  }

  ngOnInit(): void {
    if (!this.data?.copy) {
      if (!this.isLuminea || (this.isLuminea && this.userLuminea)) {
        if ((!this.data || !this.data?.logout) && !(this.url.includes('pre-vendita') || this.url.includes('pre-check') || this.url.includes('esito'))) this.copy = 'Sei sicuro di voler uscire? La pratica sarà salvata nella sezione Bozze.';
        if (this.data && this.data.logout && !(this.url.includes('pre-vendita') || this.url.includes('pre-check') || this.url.includes('esito'))) this.copy = 'Sei sicuro di voler uscire? La pratica è stata salvata nella sezione Bozze.';
      } else {
        this.copy = "Gentile utente, proseguendo la vendita Luminea verrà assegnata ad un altro utente per poter essere completata. Può modificare la parte commodity fino all' assegnazione della pratica. Sei sicuro di voler uscire?";
      }
    } else {
      this.copy = this.data.copy;
    }
  }
  installationAddressHandler(response: boolean) {
    this.store.dispatch(CheckIndirizzoInstallazioneConnettivitaAction({ isIndirizzoInstallazioneConnettivita: false }));
    this.dialogRef.close(response);
  }
}
