import { Notifications } from '@modules/login/login.interface';
import { TrainingLoadedListAction } from '@modules/training/store/training.actions';
import { on } from '@ngrx/store';
import {
  DeleteFlatOfferAction,
  FireBaseRemoteConfigAction,
  NotificationsGetDailyTaskListAction,
  NotificationsGetListAction,
  NotificationsUpdatedVisualizedAction,
  ResetPaStoreKey,
  SetAllAgencyAction,
  SetFlatOfferAction,
  SetHomePageBundleItemAction,
  SetHomePageCommodityItemAction,
  SetHomePageVasItemAction,
  SetMandatoryTrainingFlagAction,
  TrainingHomepageLoadedListAction
} from '../store.action';
import { AuthSetAuthFirstAction, AuthSetAuthQrCodeAction, SetAggregatedTracking, SetProfileAction } from './user.actions';

export const userReducer = [
  on(AuthSetAuthFirstAction, (state: any, action) => {
    return Object.assign({}, state, { auth: action.auth });
  }),
  on(AuthSetAuthQrCodeAction, (state: any, action) => {
    return Object.assign({}, state, { auth: action.auth });
  }),
  on(SetProfileAction, (state: any, action) => {
    return Object.assign({}, state, { profile: action.profile });
  }),
  on(FireBaseRemoteConfigAction, (state: any, action) => {
    return Object.assign({}, state, action);
  }),
  on(SetAllAgencyAction, (state: any, action) => {
    return Object.assign({}, state, { agencyList: action.agencyList });
  }),
  on(TrainingLoadedListAction, (state: any, action) => {
    return Object.assign({}, state, { trainingList: action.trainingList });
  }),
  on(TrainingHomepageLoadedListAction, (state: any, action) => {
    return Object.assign({}, state, { trainingListHomepage: action.trainingListHomepage });
  }),
  on(NotificationsGetListAction, (state: any, action) => {
    return Object.assign({}, state, { notifications: [...action.notifications] });
  }),
  on(NotificationsGetDailyTaskListAction, (state: any, action) => {
    return Object.assign({}, state, { dailyTask: [...action.dailyTask] });
  }),
  on(NotificationsUpdatedVisualizedAction, (state: any, action) => {
    return Object.assign({}, state, { notifications: state.notifications?.map((notification: Notifications) => (notification?.id === action.notification?.id ? action.notification : notification)), dispatched: action.dispatched });
  }),
  on(SetHomePageCommodityItemAction, (state: any, action) => {
    return Object.assign({}, state, { catalogCommodityHomeItem: action.catalogCommodityHomeItem });
  }),
  on(SetHomePageBundleItemAction, (state: any, action) => {
    return Object.assign({}, state, { catalogBundleHomeItem: action.catalogBundleHomeItem });
  }),
  on(SetHomePageVasItemAction, (state: any, action) => {
    return Object.assign({}, state, { catalogVasHomeItem: action.catalogVasHomeItem });
  }),
  on(SetAggregatedTracking, (state: any, action) => ({ ...state, ...action })),
  on(SetMandatoryTrainingFlagAction, (state: any, action) => ({ ...state, ...action })),
  on(SetFlatOfferAction, (state: any, action) => ({ ...state, ...action })),
  on(DeleteFlatOfferAction, (state: any, action) => ({ ...state, flatOffer: {} })),
  on(ResetPaStoreKey, (state: any, action) => ({ ...action.emptyStoreState }))
];
