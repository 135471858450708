import { Vas } from '@modules/catalog/catalog.interface';
import { createAction, props } from '@ngrx/store';
import * as State from './sales.state';
import { QuotationPackAndChangeLCDPackResponse } from '../components/sales-rc-steps/rc.interface';

export const SalesClearActionType = '[sales] Clearing';
export const SalesClearAction = createAction('[sales] Clearing');
export const SalesRoutingAction = createAction('[sales] Navigating through stepper pages.', props<{ stepperRoutes: State.SalesStepperRoutes }>());
export const SalesPrevenditaTypeAction = createAction('[sales] Choosing Prevendita Typology', props<{ prevenditaType: State.SalesPrevenditaType; uuid: string }>());
export const SalesLoadAction = createAction('[sales] Read Configuration from the Be.');
export const SalesLoadedAction = createAction('[sales] Updating configuration.', props<Partial<State.SalesState>>());
export const SalesInitialCheckLoadAction = createAction('[sales] Read InitialCheck from Be.', props<{ initialCheck: Partial<State.SalesInitialCheck>; prevenditaType: State.SalesPrevenditaType }>());
export const SalesInitialCheckLoadedAction = createAction('[sales] InitialCheck read from Be.', props<{ initialCheck: Partial<State.SalesInitialCheck> }>());
export const SalesSetInitialCheckAction = createAction('[sales] InitialCheck set.', props<{ initialCheck: Partial<State.SalesInitialCheck> }>());
export const SalesEsitoLoadAction = createAction('[sales] Load Esito from Be.', props<{ esito: Partial<State.SalesEsito> | null }>());
export const SalesEsitoDualLoadAction = createAction('[sales] Load Esito Dual from Be.', props<{ esitoDual: Partial<State.SalesEsito> | null }>());
export const SalesEsitoLoadedAction = createAction('[sales] Esito read from Be.', props<{ esito: Partial<State.SalesEsito> }>());
export const SalesEsitoDoneAction = createAction('[sales] Esito read from Be.', props<{ esitoDone: boolean }>());
export const SalesRandomCode = createAction('[sales] Set random code from Be.', props<{ randomCode: string }>());
export const SalesEsitoVasSelectedAction = createAction('[sales] Esito Vas Selected.', props<{ selectedVas: Vas }>());
export const SalesContrattualizzazioneLoadedAction = createAction('[sales] Contrattualizzazione read from Be.', props<{ contrattualizzazione: Partial<State.SalesContrattualizzazione>; feaOnBoarding: boolean }>());
export const SetIsBrokenBPAction = createAction('[sales] Set is Broken BP Action.', props<{ brokenBP: boolean }>());
export const SalesCommodityLoadedAction = createAction('[sales] Commodity read from Be.', props<{ commodity: Partial<State.SalesCommodity> }>());
export const SalesVasLoadedAction = createAction('[sales] Vas read from Be.', props<{ vas: Partial<State.SalesVas> }>());
export const SalesRCASetAction = createAction('[sales] RCA set summary.', props<{ rcaSummary: Partial<State.RcaSummary> }>());
export const SalesRCADeleteValueAction = createAction('[sales] RCA delete.', props<Partial<State.SalesState>>());
export const SalesRCACoverageSetAction = createAction('[sales] RCA set coverage.', props<{ rcaCoverage: Partial<QuotationPackAndChangeLCDPackResponse>[] }>());

export const SalesRCASelectedPackSetAction = createAction('[sales] RCA set coverage.', props<{ selectedPack: string[] | undefined }>());
export const SalesRCACoverageSelectedSetAction = createAction('[sales] RCA set coverage.', props<{ rcaCoverageSelected: Partial<QuotationPackAndChangeLCDPackResponse> }>());
export const SurveyRCSetAction = createAction('[sales] RC set survey.', props<{ surveyRc: Partial<State.SurveyRc> }>());

export const SalesRaccoltaDatiLoadedAction = createAction('[sales] RaccoltaDati read from Be.', props<{ raccoltaDati: Partial<State.SalesRaccoltaDati> }>());
export const SalesRaccoltaDatiCambioOffertaAction = createAction('[sales] RaccoltaDati read change offer.', props<{ cambioOffertaData: Partial<State.SalesRaccoltaDati> }>());
export const SalesRiepilogoLoadedAction = createAction('[sales] RaccoltaDati read.', props<{ riepilogo: any }>());
export const SalesInvioLoadedAction = createAction('[sales] Invio read from Be.', props<{ invio: any }>());
export const SalesExitAction = createAction('[sales] Exit.', props<{ save: boolean }>());
export const SalesLeavingAction = createAction('[sales] Leaving.', props<{ leaving: boolean }>());
export const SalesDraftAction = createAction('[sales] Draft.', props<Partial<State.SalesState>>());
export const SalesSetVasBundleAction = createAction('[sales] Set Vas list for bundle.', props<{ vasList: Partial<Vas>[] }>());
export const SalesLeadIdChannel = createAction('[sales] Set lead Id channel', props<{ provenanceChannel: string }>());
export const SalesAcceptDate = createAction('[sales] accept date', props<{ acceptDate: string }>());
export const SalesDocsRequired = createAction('[sales] accept date', props<{ docsRequired: State.SalesDocsRequired }>());
export const lastIndex = createAction('[sales] last index', props<{ lastIndex: number }>());
export const prewinBack = createAction('[sales] prewinBack', props<{ prewinBack: boolean }>());
export const ChangeOfferGoBack = createAction('[sales] changeOfferGoBack', props<{ changeOfferGoBack: boolean }>());
export const CompletedModule = createAction('[sales] completed module', props<{ completedModule: boolean }>());
export const VulnerabilityModule = createAction('[sales] Vulnerability module', props<{ vulnerabilityModule: Partial<State.Vulnerability> }>());
export const ConnettivitaSevSetAction = createAction('[sales] Set Connettivita Sev.', props<{ connettivitaSev: Partial<State.ConnettivitaSev> }>());
export const CheckIndirizzoInstallazioneConnettivitaAction = createAction('[sales] Indirizzo Installazione Connettivita', props<{ isIndirizzoInstallazioneConnettivita: boolean }>());
export const WoRepricingAction = createAction('[sales] Id work order repricing', props<{ idQuoteRepricing: string }>());
