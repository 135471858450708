import { Injectable } from '@angular/core';
import { IStoreState } from '@core/store/store.state';
import { AuthSelector } from '@core/store/user/user.selectors';
import { ILogin } from '@modules/login/login.interface';
import { Store } from '@ngrx/store';
import { Primitive } from '@ngrx/store/src/models';
import * as Sentry from '@sentry/angular-ivy';
import * as SentryTypes from '@sentry/types';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MetricsService {
  private userAuth: Partial<ILogin>;
  constructor(store: Store<IStoreState>) {
    store
      .select(AuthSelector)
      .pipe(filter(auth => !!auth))
      .subscribe(auth => (this.userAuth = auth!));
  }

  public newTransaction(name: string): SentryTransaction {
    this.setTagUserSentry();
    return new SentryTransaction(name);
  }

  private setTagUserSentry() {
    const user: Sentry.User = {
      email: this.userAuth?.username || 'N/A',
      token: this.userAuth?.idToken || 'N/A'
    };

    Sentry.configureScope(scope => scope.setUser(user));
  }
}

class SentryTransaction {
  // private readonly transaction: SentryTypes.Transaction;
  private readonly transaction: any;
  private readonly spans: SentrySpan[] = [];

  constructor(name: string) {
    this.transaction = Sentry.startTransaction({ name: name });
  }

  public newSpan(name: string, closeOnTransactionEnd: boolean = true): SentrySpan | undefined {
    const span = new SentrySpan(this.transaction, name);
    if (closeOnTransactionEnd) this.spans.push(span);
    else return span;

    return undefined;
  }

  public closeTransaction() {
    this.spans.forEach(span => span.closeSpan());
    this.transaction.finish();
  }
}

class SentrySpan {
  private readonly span: SentryTypes.Span;

  constructor(transaction: SentryTypes.Transaction, name: string) {
    this.span = transaction.startChild({ op: name });
  }

  public closeSpan() {
    this.span.finish();
  }
}

export type { SentryTransaction, SentrySpan };
type SpanTag = { [key: string]: Primitive };
