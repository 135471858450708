import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from 'src/app/app.module';
import { environment } from 'src/environments/environment';

Sentry.init({
  dsn: environment.sentryDsn,
  debug: false,
  environment: environment.name,
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. We recommend adjusting this value in production
  tracesSampleRate: 0.5, // todo change to 0.1 at 01/10/2023
  // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
  // replaysSessionSampleRate: 1,
  // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
  replaysOnErrorSampleRate: 1,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [environment.PA_BASE_URL],
      routingInstrumentation: Sentry.routingInstrumentation
    }),
    new Sentry.Replay({
      //networkDetailAllowUrls: ['*!/api/proxy/private/v2/precheck/full'],
      networkRequestHeaders: ['application/json, text/plain, *!/!*'],
      networkResponseHeaders: ['Content-Type,Content-Length,Authorization,Accept,X-Requested-With, sentry-trace, baggage'],
      maskAllText: false,
      blockAllMedia: true
    })
  ]
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error());
