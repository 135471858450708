import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { TrainingRequiredAccessGuard } from './guards/training-required-access.guard';
import { TrainingRequiredGuard } from './guards/training-required.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('@modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'forgotten-psw',
    loadChildren: () => import('@shared/forgotten-psw/forgotten-psw.module').then(m => m.ForgottenPswModule)
  },
  {
    path: 'edit-password-first/:confirmation/:username/:tenantDomain',
    loadChildren: () => import('@shared/edit-password/edit-password.module').then(m => m.EditPasswordModule)
  },
  {
    path: 'edit-password-reset/:pin',
    loadChildren: () => import('@shared/edit-password/edit-password.module').then(m => m.EditPasswordModule)
  },
  {
    path: 'choice-mfa',
    canActivate: [LoginGuard],
    loadChildren: () => import('@shared/mfa-choice/mfa-choice.module').then(m => m.MfaChoiceModule)
  },
  {
    path: 'otp',
    canActivate: [LoginGuard],
    loadChildren: () => import('@shared/otp-input/otp-input.module').then(m => m.OtpInputModule)
  },
  {
    path: 'reset-password/:reset',
    loadChildren: () => import('@shared/otp-input/otp-input.module').then(m => m.OtpInputModule)
  },
  {
    path: 'my-area',
    canActivate: [AuthGuard, TrainingRequiredGuard],
    loadChildren: () => import('@modules/core/core.module').then(m => m.CoreModule)
  },
  {
    path: 'testCSV',
    loadChildren: () => import('@modules/sync-csv/sync-csv.module').then(m => m.SyncCSVModule)
  },
  {
    path: 'training-required',
    canActivate: [TrainingRequiredAccessGuard],
    loadChildren: () => import('@modules/training/training.module').then(m => m.TrainingModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
