import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IGenericHttpResponse } from '@modules/login/login.interface';
import { ContractRequest, ContractResponse, PostSalesService } from '@modules/post-sales/services/post-sales.service';
import { map, Observable, tap } from 'rxjs';
import * as State from '../store/sales.state';
import { CodiceScontoVasRequest, CodiceScontoVasResponse, OutGoingSeller, RcaInfoByPlate, SalesApiService, SportelliResponse, UploadDocumentRequest, UploadPrecompiledPlicoRequest } from './sales-api.service';
import { CheckBillingProfileRequest, CheckBillingProfileResponse, CheckPodPdrResponse, GetPdrResponse, GetPodResponse, Plico } from './sales-common.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  private isExitFromFunnel: boolean = false;
  public GET_PLICO: string = '/plico-cartaceo?randomCode=';
  public GET_WORK_ORDER: string = '/sales/switchout/get-work-order';
  public GET_LEAD_CHECK_PRESENCE: string = '/Lead/check-presence';
  public POST_RESEND_SIGNATURE: string = '/resend-signature';
  public CHECK_RANDOM_CODE: string = '/sales/checkRandom';

  constructor(private apiService: SalesApiService, private http: HttpClient, private postSalesService: PostSalesService) {}
  get IsExitFromFunnel() {
    return this.isExitFromFunnel;
  }
  set IsExitFromFunnel(bool: boolean) {
    this.isExitFromFunnel = bool;
  }
  getPod(body: any): Observable<Partial<GetPodResponse>> {
    return this.apiService.getPod(body);
  }
  getPdr(body: any): Observable<Partial<GetPdrResponse>> {
    return this.apiService.getPdr(body);
  }
  checkPodPdr(body: any): Observable<Partial<CheckPodPdrResponse>> {
    return this.apiService.checkPodPdr(body);
  }
  getSalesInitialCheck(action: Partial<State.SalesInitialCheck>, newCustomer: boolean): Observable<State.SalesEsito> {
    return this.apiService.getSalesInitialCheck(action, newCustomer);
  }
  getSalesVasInitialCheck(action: any): Observable<State.SalesEsito> {
    return this.apiService.getSalesVasInitialCheck(action);
  }
  saveDraftPractice(body: any): Observable<any> {
    return this.apiService.saveDraftPractice(body);
  }
  saveFinalSalesState(body: any, type: string): Observable<any> {
    return this.apiService.saveFinalSalesState(body, type);
  }
  checkMarketability(body: any): Observable<any> {
    return this.apiService.checkMarketability(body);
  }
  getVenditoriUscenti(): Observable<OutGoingSeller[]> {
    return this.apiService.getVenditoriUscenti();
  }
  getSportelli(userCompany: string): Observable<Partial<SportelliResponse>[]> {
    return this.apiService.getSportelli(userCompany);
  }
  getRandomCode(): Observable<string> {
    return this.apiService.getRandomCode();
  }
  getFea(body: any, noFilter: boolean, noError: boolean): Observable<any> {
    return this.postSalesService.getRegistry(body, noFilter, noError);
  }
  getContract(body: Partial<ContractRequest>): Observable<ContractResponse> {
    return this.postSalesService.getContract(body);
  }
  checkBillingProfile(body: Partial<CheckBillingProfileRequest>): Observable<Partial<CheckBillingProfileResponse>> {
    return this.apiService.checkBillingProfile(body);
  }
  uploadPrecompiledPlico(body: UploadPrecompiledPlicoRequest, vas?: string): Observable<any> {
    return this.apiService.uploadPrecompiledPlico(body, vas);
  }
  uploadVerbalOrder(body: UploadPrecompiledPlicoRequest): Observable<any> {
    return this.apiService.uploadVerbalOrder(body);
  }
  getMp3Contr(randomCode: string): Observable<string> {
    return this.apiService.getMp3Contr(randomCode);
  }
  uploadMp3Contr(body: UploadPrecompiledPlicoRequest): Observable<string> {
    return this.apiService.uploadMp3Contr(body);
  }
  archiveDocument(body: UploadDocumentRequest): Observable<any> {
    return this.apiService.archiveDocument(body);
  }
  getPlico(randomCode: string): Observable<Partial<Plico>> {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.GET_PLICO + `${randomCode}`).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res.data as Partial<Plico>),
      map(res => {
        return {
          base64: res.base64,
          fileType: res.fileType,
          randomCode: res.randomCode
        };
      })
    );
  }
  getLeadCheckPresence(filter: any) {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.GET_LEAD_CHECK_PRESENCE}`, { params: filter }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'Lead- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => {
        return {
          codice: res.status.codice,
          descrizione: res.status.descrizione,
          esito: res.status.esito,
          lead: res.data ? res.data : []
        };
      })
    );
  }

  getWorkOrder(supplyPoint: string): Observable<any> {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.GET_WORK_ORDER, { params: { supplyPoint } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => res)
    );
  }
  resendSignature(body: any) {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.POST_RESEND_SIGNATURE}`, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      })
    );
  }

  checkRandomCode(randomCode: string) {
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.CHECK_RANDOM_CODE}`, { params: { randomCode } }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'SALES- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(res => {
        return res.data;
      })
    );
  }

  uploadDocument(body: any): Observable<string> {
    return this.apiService.uploadDocument(body);
  }

  getRcaInfoByPlate(body: any): Observable<RcaInfoByPlate> {
    return this.apiService.getRcaInfoByPlate(body);
  }

  getCheckCodiceScontoVas(body: Partial<CodiceScontoVasRequest>): Observable<Partial<CodiceScontoVasResponse>[]> {
    return this.apiService.getCheckCodiceScontoVas(body);
  }
}
