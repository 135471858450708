import { MatPaginatorIntl } from '@angular/material/paginator';
import { Sidebar } from '@components/sidebar/sidebar.interface';
import { SocketIoConfig } from 'ngx-socket-io';
import { environment } from '@env/environment';

export const socketIoConfig: SocketIoConfig = { url: environment.PA_MASSIVE_URL, options: { transports: ['websocket'], autoConnect: false } };

export const sidebarConfig: Sidebar[] = [
  {
    title: 'Homepage',
    url: 'homepage',
    icon: 'home_filled'
  },
  {
    title: 'Catalogo',
    url: 'catalog',
    icon: 'window'
  },
  {
    title: 'Pre-Check e Vendita',
    url: 'sales',
    icon: 'local_grocery_store'
  },
  {
    title: 'Preventivatore FLAT',
    url: 'flat-offers',
    icon: 'calculate'
  },
  {
    title: 'Gestione Pratiche Massive',
    url: 'massive-practices',
    icon: 'logout'
  },
  {
    title: 'Tracking Pratiche',
    url: 'tracking-contracts',
    icon: 'manage_search'
  },
  {
    title: 'Gestione Pratiche VAS',
    url: 'tracking-contracts-luminea',
    icon: 'find_in_page'
  },
  {
    title: 'Post Sales',
    url: 'post-sales',
    icon: 'note_alt'
  },
  {
    title: 'Monitoraggio KPI',
    url: 'kpi-monitoring',
    icon: 'insights'
  },
  {
    title: 'Monitoraggio KPI BOE',
    url: 'kpi-boe-monitoring',
    icon: 'monitoring'
  },
  {
    title: 'Anagrafica Agenti',
    url: 'agent-profile',
    icon: 'people_alt'
  },
  {
    title: 'Formazione',
    url: 'training',
    icon: 'auto_stories'
  },
  {
    title: 'Gestione Lead',
    url: 'lead-management',
    icon: 'mark_chat_read'
  },
  {
    title: 'Campagne Assegnate',
    url: 'assigned-campaign',
    icon: 'campaign'
  },
  {
    title: 'Instant Call',
    url: 'instant-call',
    icon: 'add_call'
  },
  {
    title: 'Gestione BOE',
    url: 'boe',
    icon: 'ring_volume'
  },
  {
    title: 'Configura Agenzie',
    url: 'agencies-manager',
    icon: 'store'
  },
  {
    title: 'Caricamento Prewinback',
    url: 'prewinback-conf',
    icon: 'assignment_return'
  },
  {
    title: 'Verifica Copertura',
    url: environment.linkOPNet,
    icon: 'network_check'
  },
  {
    title: 'Gestisci Recensioni',
    url: 'recensioni',
    icon: 'forum'
  }
];

export const sidebarBoeConfig: Sidebar[] = [
  {
    title: 'Homepage',
    url: 'homepage-boe',
    icon: 'home_filled'
  },
  {
    title: 'Gestione pratiche massive',
    url: 'massive-practices-boe',
    icon: 'logout'
  },
  {
    title: 'Tracking Pratiche',
    url: 'tracking-contracts-boe/NN',
    icon: 'find_in_page'
  },
  {
    title: 'Monitoraggio KPI',
    url: 'kpi-monitoring',
    icon: 'insights'
  },
  {
    title: 'Monitoraggio KPI BOE',
    url: 'kpi-boe-monitoring',
    icon: 'insights'
  },
  {
    title: 'Anagrafica agenti',
    url: 'agent-profile',
    icon: 'people_alt'
  },
  {
    title: 'Formazione',
    url: 'training',
    icon: 'auto_stories'
  }
];

export const sidebarEditorConfig: Sidebar[] = [
  {
    title: 'Caricamento lead freddi',
    url: 'editor/goal-list',
    icon: 'auto_stories'
  }
];

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Elementi per pagina';

  return customPaginatorIntl;
}
