import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { MetricsService, SentryTransaction } from '../metrics/service/metrics.service';
import { IStoreState } from '../store/store.state';
import { TokenSelector } from '../store/user/user.selectors';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<IStoreState>, private metricsService: MetricsService) {}

  private funnelIncludesLinks = [
    '/nds/sales/standalone',
    '/sales/upload-document',
    '/sales/voltura-switching',
    '/sales/voltura',
    '/sales/change/offer',
    '/sales/attivazione-subentro/dual',
    '/sales/attivazione-subentro',
    '/sales/switchin/mono',
    '/sales/switchin/dual',
    '/random-code',
    '/venditori-uscenti',
    '/nds/vendibilita-vas',
    '/check-offer',
    '/precheck/full',
    '/customer-check',
    '/registry/ele-dl',
    '/sales/precompiled/signed',
    '/archive-document',
    '/registry/gas-dl',
    '/billing-contract',
    '/sale-state'
  ];
  private postSalesIncludesLinks = ['/contract', '/anagrafica', '/postsales/variazione-anagrafica', '/postsales/sdd-module', '/process-compatibility', '/postsales/attivazione-mop', '/postsales/variazione-mop', '/plico-crm'];
  private trackingIncludesLinks = ['/tracking-sales', '/tracking-postsales', '/boe-practice/submit', '/boe-practice/nds/submit', '/boe/resend-optin', '/boe/update-note', '/boe/nds/update-note', '/practice/lock', '/practice/processable', '/boe-practice/download', '/boe-practice/nds/download'];

  private INCLUDED_API_ENDPOINTS = [
    '/auth/', //TUTTE LE API DI LOGIN
    '/reset-password', //CAMBIO PASSWORD
    '/profile', // PROFILO OPERATORE,
    ...this.postSalesIncludesLinks,
    ...this.funnelIncludesLinks,
    ...this.trackingIncludesLinks
  ];
  private NOT_INCLUDED_API_ENDPOINTS = ['aggregated'];
  private endpointWithoutToken: string[] = ['ingest.sentry.io', 'auth/login', 'api.cloudinary.com', 'verify/captcha', 'reset-password'];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldNotInclude = !this.NOT_INCLUDED_API_ENDPOINTS.some(el => request.url.includes(el));
    const shouldInclude = this.INCLUDED_API_ENDPOINTS.some(endpoint => request.url.includes(endpoint));

    let transaction: SentryTransaction;
    if (shouldInclude && shouldNotInclude) transaction = this.metricsService.newTransaction('Calling ' + request.url);
    return this.store.pipe(
      select(TokenSelector),
      take(1),
      map(token => {
        if (!this.endpointWithoutToken.some(endpoint => request.url.includes(endpoint))) {
          if (transaction) transaction.newSpan('Adding token ' + token);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }
        return request;
      }),
      tap(() => {
        if (transaction) transaction.newSpan('Executing call');
      }),
      mergeMap(req => next.handle(req)),
      tap(() => {
        if (transaction) transaction.closeTransaction();
      })
    );
  }
}
