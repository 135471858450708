import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MetricsService, SentryTransaction } from '../metrics/service/metrics.service';
import { AuthLogoutAction } from '../store/user/user.actions';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  private INCLUDED_API_ENDPOINTS = [
    '/auth/', //TUTTE LE API DI LOGIN
    '/reset-password', //CAMBIO PASSWORD
    '/profile', // PROFILO OPERATORE
    '/operator', //ANAGRAFICA AGENTI
    'postsales/', //VARIAZIONI POSTSALES
    'change/offer', //CAMBIO OFFERTA SALES
    'sales/' //CONTRATTUALIZZAZIONI SALES
  ];

  constructor(private store: Store, private metricsService: MetricsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldInclude = this.INCLUDED_API_ENDPOINTS.some(endpoint => request.url.includes(endpoint));
    let t: SentryTransaction;
    return next.handle(request).pipe(
      tap({
        next: (err: any) => {
          if (err.body) {
            if (shouldInclude && err.body.status === 401) t = this.metricsService.newTransaction(`HttpErrorResponse for call ${request.url} with code ${err.status}.`);

            if (err.body.status === 401) {
              if (t) t.newSpan('Logging out');
              this.store.dispatch(AuthLogoutAction({ goToLogin: true, number: '15', noLogout: true }));
            }

            if (t) t.closeTransaction();
          }
        }
      })
    );
  }
}
