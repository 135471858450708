import { CreateDailyTask, IGenericHttpResponse, Notifications } from '@modules/login/login.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map, Observable, tap } from 'rxjs';
import { clearEmptyKeyFromObject } from '@constants/constants';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public NOTIFICATION_LIST: string = '/notification/search';
  public CREATE_NOTIFICATION_URL: string = '/notification/insert';
  public UPDATE_VISUALIZED_NOTIFICATION_URL: string = '/notification/updateVisualized/?id=';
  public UPDATE_NOTIFICATION_URL: string = '/notification/updateNotification';

  constructor(private http: HttpClient) {}

  getNotificationList(operatorId: string, onlyUnread: boolean, type?: string): Observable<Partial<Notifications>[]> {
    let filter = clearEmptyKeyFromObject({ operatorId: operatorId, onlyUnread: onlyUnread, type: type });
    return this.http.get<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL}${this.NOTIFICATION_LIST}`, { params: filter }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'NOTIFICHE- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => (res.data || []) as Partial<Notifications>[]),
      map(res =>
        res.map(res => ({
          id: res.id,
          notification: res.notification,
          operatorId: res.operatorId,
          visualized: res.visualized
        }))
      )
    );
  }

  createDailyTask(dailyTask: Partial<CreateDailyTask>): Observable<Partial<CreateDailyTask>> {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.CREATE_NOTIFICATION_URL}`, dailyTask).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'NOTIFICHE- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as Partial<CreateDailyTask>),
      map(res => ({
        id: res?.id,
        text: res?.text,
        type: res?.type,
        userNotification: res?.userNotifications
      }))
    );
  }

  updateDailyTask(dailyTask: Partial<CreateDailyTask>): Observable<Partial<CreateDailyTask>> {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.UPDATE_NOTIFICATION_URL}`, dailyTask).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'NOTIFICHE- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as Partial<CreateDailyTask>),
      map(res => ({
        id: res?.id,
        text: res?.text,
        type: res?.type,
        userNotification: res?.userNotifications
      }))
    );
  }

  createNotification(notification: Partial<Notifications>): Observable<Partial<Notifications>> {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.CREATE_NOTIFICATION_URL}`, notification).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'NOTIFICHE- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      }),
      map(res => res.data as Partial<Notifications>),
      map(res => ({
        id: res.id,
        notification: res.notification,
        operatorId: res.operatorId,
        visualized: res.visualized,
        redirectObject: res.redirectObject
      }))
    );
  }

  updateVisualizedNotification(id: number) {
    return this.http.post<IGenericHttpResponse>(`${environment.PA_PRIVATE_BASE_URL + this.UPDATE_VISUALIZED_NOTIFICATION_URL}${id}`, null).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'NOTIFICHE- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          //this.errorService.setError(errorMessage)
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
          // todo redirect to oops page with error message
        }
      })
    );
  }
}
