import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireRemoteConfigModule, SETTINGS } from '@angular/fire/compat/remote-config';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { CustomPaginator, socketIoConfig } from '@constants/configurations';
import { AppRoutingModule } from '@core/app-routing.module';
import { AppComponent } from '@core/app.component';
import { AuthExpiredInterceptor } from '@core/interceptors/auth-expired.interceptor';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { UserEffects } from '@core/store/user/user.effects';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular-ivy';
import { CustomDateAdapter } from '@services/custom-date-adapter';
import { UtilityService } from '@services/utility.service';
import { ModalModule } from '@shared/modal/modal.module';
import { OtpInputModule } from '@shared/otp-input/otp-input.module';
import { SpinnerModule } from '@shared/spinner/spinner.module';
import { SocketIoModule } from 'ngx-socket-io';
import { getStoreReducers, persistStoreReducer } from './store/store.reducer';
import { GlobalChunckErrorHandler } from './error-handler/chunk-error-handler';
import { KickOutModalModule } from '@shared/kickout-modal/kickout-modal.module';

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(getStoreReducers(), {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      },
      metaReducers: [persistStoreReducer]
    }),
    EffectsModule.forRoot([UserEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireRemoteConfigModule,
    AngularFireAnalyticsModule,
    Sentry.TraceModule,
    AppRoutingModule,
    SpinnerModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    MatDialogModule,
    OtpInputModule,
    KickOutModalModule,
    ModalModule,
    MatNativeDateModule,
    KickOutModalModule,
    SocketIoModule.forRoot(socketIoConfig)
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalChunckErrorHandler },
    { provide: MAT_DATE_LOCALE, useValue: 'it' },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter
    },
    {
      provide: SETTINGS,
      useFactory: () => (isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {})
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    UtilityService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
