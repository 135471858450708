<div class="modal">
  <div class="modal__body">
    <div class="modal__text">
      <h1 [ngStyle]="getTitleStyles()">{{ data.title }}</h1>
      <img width="60" height="30" src="assets/images/svg/error.svg" alt="" *ngIf="data.status === modalStatus.ERROR" />
      <img width="60" height="30" src="assets/images/svg/success.svg" alt="" *ngIf="data.status === modalStatus.SUCCESS" />
      <img width="60" height="30" src="assets/images/svg/warning.svg" alt="" *ngIf="data.status === modalStatus.WARNING" />
      <ng-container *ngIf="data.pointBreak?.enable; else asisMess">
        <div *ngFor="let message of getMessagePointBreak(data.message || '', data.pointBreak?.separator || '')">
          <span class="modal__message" [ngStyle]="getMessageStyles()">{{ message }}</span>
        </div>
      </ng-container>
      <ng-template #asisMess>
        <span class="modal__message" [ngStyle]="getMessageStyles()">{{ data.message }}</span>
      </ng-template>
      <ng-container *ngIf="data.message">
        <button mat-button color="accent" *ngIf="data?.status === modalStatus.ERROR && data?.message && !data?.noCopy && !data?.message?.includes('Generico') && data?.message?.includes('-')" [cdkCopyToClipboard]="data.message || ''"><mat-icon color="accent">file_copy</mat-icon></button>
      </ng-container>
      <ng-container *ngIf="data.optionalMessage">
        <span class="modal__message" *ngIf="data.optionalMessage">{{ data.typeOptionalMessage ? data.typeOptionalMessage + ': ' + data.optionalMessage : data.optionalMessage }}</span>
        <button mat-button color="accent" *ngIf="data.copyOptionalMessage" [cdkCopyToClipboard]="data.optionalMessage || ''"><mat-icon color="accent">file_copy</mat-icon></button>
      </ng-container>
    </div>
    <mat-icon (click)="returnHp()" class="modal__close" mat-raised-button color="accent" mat-dialog-close>close</mat-icon>
  </div>
  <div *ngIf="data.status === modalStatus.GENERIC && data.ctaType === ModalCtaTypes.PROCEED_OR_CANCEL" class="modal__buttons">
    <button mat-raised-button [mat-dialog-close]="false">Annulla</button>
    <button mat-raised-button color="accent" [mat-dialog-close]="true">Procedi</button>
  </div>
  <div *ngIf="data.status === modalStatus.GENERIC && data.ctaType === ModalCtaTypes.BACK_OR_CANCEL" class="modal__buttons">
    <button mat-raised-button color="accent" [mat-dialog-close]="true">Torna indietro</button>
  </div>
  <div *ngIf="data.status === modalStatus.GENERIC && data.ctaType === ModalCtaTypes.CONFIRM_OR_CANCEL" class="modal__buttons">
    <button mat-raised-button [mat-dialog-close]="false">Annulla</button>
    <button mat-raised-button color="accent" [mat-dialog-close]="true">Conferma</button>
  </div>
  <div *ngIf="data.status === modalStatus.SUCCESS && data.ctaType === ModalCtaTypes.GO_TO_HOMEPAGE" class="modal__button-home">
    <button mat-raised-button color="accent" [mat-dialog-close]="true">Torna a Homepage</button>
  </div>
  <div *ngIf="data.funnelCommodity" class="modal__buttons">
    <button [routerLink]="['/my-area', 'homepage']" mat-raised-button [mat-dialog-close]="false">Torna a homepage</button>
  </div>
</div>
