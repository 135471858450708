import { AggregatedTracking, ILogin, Profile, UserLogin } from '@modules/login/login.interface';
import { createAction, props } from '@ngrx/store';

export const AuthLogoutAction = createAction('[User] User logout', props<{ goToLogin: boolean; number: string; noLogout?: boolean }>());
export const AuthLogoutFunnelAction = createAction('[User] User logout from Funnel');
export const AuthGetAuthAction = createAction('[User] Get Auth Data', props<{ credentials: UserLogin }>());
export const AuthSetProfileAction = createAction('[User] Set Auth Profile Data');
export const AuthSetAuthQrCodeAction = createAction('[User] Set Auth QrCode', props<{ auth: Partial<ILogin> }>());
export const AuthSetAuthFirstAction = createAction('[User] Set Auth First Data', props<{ auth: Partial<ILogin> }>());
export const AuthGetOtpAction = createAction('[User] Get Auth Otp', props<{ otp: string; firstAccess: boolean; auth: Partial<ILogin>; mfa: boolean }>());
export const GetProfileAction = createAction('[Profile] Get Profile List');
export const SetProfileAction = createAction('[User] Set Profile', props<{ profile: Partial<Profile> }>());
export const SetAggregatedTracking = createAction('[Homepage] Set Aggregated Tracking', props<{ aggregatedTracking: Partial<AggregatedTracking> | null }>());
