import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clearEmptyKeyFromObject } from '@constants/constants';
import { environment } from '@env/environment';
import { IGenericHttpResponse } from '@modules/login/login.interface';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordEditService {
  private readonly CHANGE_PASSWORD_URL = '/auth/change-password';
  private readonly RESET_PASSWORD_BY_USER_URL = '/auth/reset-password-by-user';
  private readonly VALIDATE_FIRST_ACCESS_CODE_URL = '/auth/first-access/verify-otp';

  constructor(private http: HttpClient) {}

  postEditedPassword(body: EditPasswordI): Observable<boolean> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.CHANGE_PASSWORD_URL, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'PASSWORD- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(el => el.status.codice === '000')
    );
  }
  postResetPassword(body: ResetPasswordI): Observable<boolean> {
    return this.http.post<IGenericHttpResponse>(environment.PA_PUBLIC_BASE_URL + this.RESET_PASSWORD_BY_USER_URL, body).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'PASSWORD- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, message: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(el => el.status.codice === '000')
    );
  }

  validateFirstAccessCode(otp: string, transactionId?: string): Observable<boolean> {
    return this.http.get<IGenericHttpResponse>(environment.PA_PRIVATE_BASE_URL + this.VALIDATE_FIRST_ACCESS_CODE_URL, { params: clearEmptyKeyFromObject({ code: otp, transactionId: transactionId }) }).pipe(
      tap(response => {
        if (response.status.codice !== '000') {
          const errorMessage = 'PASSWORD- ' + response.status.codice + ' - ' + response.status.descrizione + ' - ' + response.correlationId;
          throw { code: response.status.codice, error: new Error(errorMessage) }; //new Error(errorMessage);
        }
      }),
      map(el => el.data)
    );
  }
}

export interface EditPasswordI {
  newPassword: string;
  oldPassword: string;
}

export interface ResetPasswordI {
  password: string;
  key: string;
}

export interface ResetFirstPasswordI {
  confirmation: string;
  username: string;
  tenantDomain: string;
  password: string;
}
