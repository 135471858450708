import { currentUser } from '@core/store/user/user.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, take } from 'rxjs';
import { Profile } from '@modules/login/login.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  loadingSession$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor(private store: Store) {
    this.store.select(currentUser).subscribe(el => (this.user = el));
  }

  user: Partial<Profile>;

  getUserProfileObservable() {
    return this.store.select(currentUser);
  }
  getUserProfile() {
    return this.user;
  }
  setUserProfile(user: Partial<Profile>) {
    this.user = user;
  }

  destroyLoading() {
    this.loadingSession$.unsubscribe();
  }

  getPermission() {
    if (this.user.permissions?.length) {
      return this.user.permissions;
    } else {
      return this.user.profile?.permissions?.map(el => el.permission);
    }
  }
}
